<template>
    <div class="wrapper">


        <section class="encabezado" data="plan-estrategico">

            <h2 class="title -white">Plan Operativo de Gobierno</h2>

            <h3 class="subtitle">
                ¿Cómo vamos a conseguirlo?
            </h3>

        </section>

        <nav class="breadcrumb">
            <div class="container row-start">
                <router-link to="/">Inicio</router-link>
                <router-link to="/">Compromisos de Gobierno</router-link>
                <router-link :to="$t('enlaces.planoperativo')">Plan Operativo de Gobierno ¿Cómo vamos a conseguirlo?
                </router-link>
                <router-link :to="$route.fullPath">Eje estratégico</router-link>
            </div>
        </nav>

        <a @click="back" class="back">Volver</a>


        <section class="tips-ficha">
            <div class="container">
                <nav class="tips-ficha-intro row-center ais" v-if="dominios.ejes && ejeactivo">
                    <a @click="ejeactivo = eje" :active="ejeactivo.id == eje.id" class="box -primary-line"
                        v-for="(eje, key) in dominios.ejes" :key="key">
                        <span>{{ eje.nombre }}</span>
                    </a>
                </nav>
            </div>
        </section>

        <template v-if="ejeactivo">
            <section class="contenedor-tip-objetivo row-center ais">
                <div class="container row-center">
                    <div class="left">
                        <h2>{{ ejeactivo.nombre }}</h2>
                        <!-- <progress-bar :title="'Actuaciones terminadas'"
                            :percent="ejeactivo.actuacionesterminadas*100/ejeactivo.actuaciones" :color="'green'">
                        </progress-bar> -->

                        <progress-bar :title="'Actuaciones terminadas y en ejecución'" :percent="terminadasyenejecucion"
                            :color="'green'">
                        </progress-bar>

                        <count-down :title="'Tiempo restante del mandato'" :percent="homedata.tiempo_restante"
                            :color="'yellow'">
                        </count-down>

                    </div>
                    <div class="right">
                        <div class="stats row-center">
                            <article class="stat -big">
                                <strong>
                                    <count-to :endVal="ejeactivo.objetivos" :separator="'.'"></count-to>
                                </strong>
                                <span>Objetivos estratégicos</span>
                            </article>

                            <article class="stat -big">
                                <strong>
                                    <count-to :endVal="ejeactivo.proyectos" :separator="'.'"></count-to>
                                </strong>
                                <span>Estrategias/ Proyectos</span>
                            </article>


                        </div>

                        <div class="contenedor-datos-graficas row-center">

                            <grafica-percent :total="ejeactivo.actuaciones" :label="'Actuaciones'"
                                :terminadas="ejeactivo.actuacionesterminadas" :ejecucion="ejeactivo.actuacionesiniciadas"
                                :planificadas="ejeactivo.actuacionesfuturas">
                            </grafica-percent>

                            <div class="stats row-center">
                                <article class="stat -small -terminadas">
                                    <strong>{{ ejeactivo.actuacionesterminadas }}</strong>
                                    <span>Terminadas</span>
                                </article>

                                <article class="stat -small -ejecucion">
                                    <strong>{{ ejeactivo.actuacionesiniciadas }}</strong>
                                    <span>En ejecución</span>
                                </article>

                                <article class="stat -small -no-iniciadas">
                                    <strong>{{ ejeactivo.actuacionesfuturas + ejeactivo.actuacionespendienteplanificar + ejeactivo.actuacionesretrasada }}</strong>
                                    <span>No Iniciadas</span>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>


        <section class="listado-objetivos">
            <div class="container">
                <h2 class="title">Objetivos estratégicos - Estrategias/Proyectos - Actuaciones</h2>

                <!-- Si ejeactivo cambia vuelvo a cargar objetivo  -->
                <div class="contenedor-objetivos" v-if="ejeactivo">
                    <objetivo v-for="(data, key) in objetivos(ejeactivo.id)" :data="data" :key="key"></objetivo>
                </div>

            </div>
        </section>


        <banner-ayudamos :data="['que-es-pog', 'plan-estrategico', 'observatorio-ciudad']"></banner-ayudamos>

    </div>
</template>


<script>



import { mapGetters } from 'vuex';

import router from '@/router';

export default {
    name: 'ficha-eje',
    mounted() {
        this.ejeactivo = this.dominios.ejes.filter(e => e.id == this.$route.params.id)[0];
    },
    data: () => ({
        ejeactivo: ''
    }),
    computed: {
        ...mapGetters({
            dominios: 'getDominios',
            homedata: 'getHomedata',
            objetivos: 'getObjetivos',
        }),
        noiniciadas() {
            return Number(this.ejeactivo.actuacionesfuturas) + Number(this.ejeactivo.actuacionespendienteplanificar) + Number(this.ejeactivo.actuacionesretrasada);
        },
        terminadasyenejecucion() {
            let noiniciadas = Number(this.ejeactivo.actuacionesfuturas) + Number(this.ejeactivo.actuacionespendienteplanificar) + Number(this.ejeactivo.actuacionesretrasada);
            let terminadasyenejecucion = Number(this.ejeactivo.actuaciones) - Number(noiniciadas);
            let porcentaje = Math.round((terminadasyenejecucion * 100) / Number(this.ejeactivo.actuaciones));

            //console.log("porcentaje: " + porcentaje + " no iniciadas: " + noiniciadas + " terminadasyenejecucion: " + terminadasyenejecucion + " actuaciones: " + this.ejeactivo.actuaciones + "");
            return Number(porcentaje);
            //return Number(this.areaseleccionada.actuaciones) - Number(this.noiniciadas) 
        },
    },
    methods: {
        back() {
            router.go(-1);
        }
    },
    watch: {
        'dominios.ejes': {
            deep: true,
            handler() {
                this.ejeactivo = this.dominios.ejes[2];


            }
        },

        'ejeactivo': {
            deep: true,
            handler() {
                //console.log("ingresa a ejeactivo");
                /** a todos los articulos le quito el atributo open */
                let articulos = document.querySelectorAll('.contenedor-objetivos article');
                articulos.forEach(articulo => {
                    /** Si existe el atributo open ejecuto el evento click  del hijo h3 */
                    if (articulo.hasAttribute('open')) {
                        articulo.querySelector('h3').click();
                    }
                });

            }
        }
    }
}
</script>